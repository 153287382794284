import { validateNoDecimals } from '@pages/Client/Calendar/components/BulkEdit/helpers/validateNoDecimals';
import { z } from 'zod';

const stringToNumber = z.string().transform((val, ctx) => {
  const parsed = Number(val);
  if (isNaN(parsed)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Not a number'
    });
    return z.NEVER;
  }
  return parsed;
});

export type StringNumber = z.input<typeof stringToNumber> | z.output<typeof stringToNumber>;

const numericFieldSchema = z
  .custom<StringNumber>()
  .default('')
  .refine((val) => val !== '', {
    message: 'Field cannot be empty'
  })
  .refine((val) => !isNaN(Number(val)), {
    message: 'Must be a number'
  })
  .refine((val) => Number(val) >= 0, {
    message: 'Cannot be less than 0'
  })
  .refine((val) => validateNoDecimals(Number(val)), {
    message: 'Must be a whole number'
  })
  .transform((val) => (typeof val === 'string' ? Number(val) : val));

const WeekdaySchema = z.object({
  standard: z.number()
});

const MonthlySchema = z.object({
  standard: z.number(),
  hidden: z.number().optional()
});

export const AdjustmentSchema = z.object({
  weekday: z.object({
    monday: WeekdaySchema,
    tuesday: WeekdaySchema,
    wednesday: WeekdaySchema,
    thursday: WeekdaySchema,
    friday: WeekdaySchema,
    saturday: WeekdaySchema,
    sunday: WeekdaySchema
  }),
  monthly: z.object({
    January: MonthlySchema,
    February: MonthlySchema,
    March: MonthlySchema,
    April: MonthlySchema,
    May: MonthlySchema,
    June: MonthlySchema,
    July: MonthlySchema,
    August: MonthlySchema,
    September: MonthlySchema,
    October: MonthlySchema,
    November: MonthlySchema,
    December: MonthlySchema
  }),
  lead_time: z.object({
    last_day: z.number(),
    two_days_to_three_days: z.number(),
    four_days_to_seven_days: z.number(),
    one_week_to_two_weeks: z.number(),
    two_weeks_to_four_weeks: z.number(),
    four_weeks_to_six_weeks: z.number(),
    one_half_months_to_three_months: z.number(),
    three_months_to_six_months: z.number(),
    six_months_plus: z.number()
  })
});

export const UserNotesSchema = z.object({
  id: z.number().optional(),
  name: z.string().nonempty({ message: 'Please enter a note' }),
  start_date: z.string().or(z.date()),
  end_date: z.string().or(z.date())
});

export const DefaultSchema = z.object({
  is_orphan_gap_correction_enabled: z.boolean(),
  remove_min_stay_based_on_lead_time: z.number().optional(),
  // in remove_min_stay_based_on_occupancy, we format the key by lead_time-percentage, since we need to prioritize longer lead time, backend need to sort them first before check the data
  remove_min_stay_based_on_occupancy: z.record(
    z.object({
      percentage: z.number(),
      lead_time: z.number()
    })
  ),
  adjustment_to_reference_room: z.number(),
  avg_price: z.number(),
  max_price: z.number(),
  min_price: z.number(),
  shoulder_night_discount_1_day: z.number(),
  shoulder_night_discount_2_days: z.number(),
  min_stay: z.number()
});

export const IndividualAdjustmentSchema = z.object({
  percentage: z.number(),
  name: z.string().nullable()
});

export const RoomTypeTagsSchema = z.record(
  z.object({
    tag_name: z.string(),
    room_types: z.array(z.number()),
    min_adjustment: z.number(),
    max_adjustment: z.number(),
    speed: z.number(),
    color: z.string(),
    id: z.string()
  })
);

export const MonthlyAveragesSchema = z.record(z.number().nullable());

export const After365DailyAveragesSchema = z.object({
  monthly_averages: MonthlyAveragesSchema
});

export const ModelWeightsSchema = z.object({
  competitor_model_weight: z.number(),
  pubp_model_weight: z.number(),
  airDNA_model_weight: z.number()
});

export const RoomConfigSchema = z.object({
  is_skip_min_stay_rule: z.boolean().optional(),
  adjustment_to_reference_room: z.number().or(z.string().transform(Number)).optional(),
  avg_price: z.number().optional(),
  max_price: z.number().optional(),
  min_price: z.number().optional(),
  min_stay: z.number().optional(),
  fix_price: z.number().optional(),
  surge_protection: z.number().optional(),
  percentage_adjustment: z.number().optional(),
  closed_rooms: z.number().optional(),
  occupancy_pricing: z.record(z.number()).optional()
});

export const DateRoomSchema = z
  .object({
    skip_date: z
      .object({
        fixed_price: z.number().nullable().optional(),
        name: z.string().optional()
      })
      .optional(),
    individual_adjustment: IndividualAdjustmentSchema.optional(),
    median_lead_time: z.number().optional(),
    PELL: z.number().optional()
  })
  .and(z.record(RoomConfigSchema));

export const RPGArgumentsSchema = z.object({
  PELL_weekday: z.number(),
  PELL_weekend: z.number(),
  median_lead_time: z.number(),
  booking_prop: z.object({
    four_days_to_seven_days: z.number(),
    four_weeks_to_six_weeks: z.number(),
    last_day: z.number(),
    one_half_months_to_three_months: z.number(),
    one_week_to_two_weeks: z.number(),
    three_months_plus: z.number(),
    two_days_to_three_days: z.number(),
    two_weeks_to_four_weeks: z.number()
  }),
  do_not_reduce_last_day: z.boolean()
});

export const ResellerSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string().email(),
  state: z.number(),
  created: z.string().refine((date) => !isNaN(Date.parse(date)), {
    message: 'Invalid date format'
  })
});

export const ResponsiblePersonSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email(),
  do_not_call: z.boolean(),
  sub_account_type: z.number(),
  language: z.string(),
  is_authorize_in_moodle: z.boolean(),
  is_collapsed_sidebar_enable: z.boolean(),
  is_updated_in_hs: z.boolean().optional()
});

export const CountrySchema = z.object({
  id: z.number(),
  name: z.string(),
  abbreviation: z.string()
});

export const CurrencySchema = z.object({
  id: z.number(),
  name: z.string(),
  symbol: z.string(),
  abbreviation: z.string()
});

export const HotelSchema = z.object({
  id: z.number(),
  min_price: z.number(),
  all_room_pricing: z.boolean().optional(),
  target_occupancy: z.number(),
  predefined_rate: z.boolean(),
  adjustment_to_reference_room_is_absolute: z.boolean(),
  adjustment_to_reference_room_is_locked: z.boolean(),
  automatic_inflation_increase: z.boolean(),
  yearly_percent_increase: z.number(),
  timezone: z.string(),
  number_of_rooms: z.number(),
  round_price_setting: z.number().or(z.null())
});

export const RoomDetailsSchema = z.object({
  id: z.number().optional(),
  name: z.string(),
  number_of_rooms: z.number(),
  virtual_room_type: z.boolean(),
  number_of_beds_per_physical_room: z.number().optional(),
  variable_cost_per_room: z.number()
});

export const RoomsSchema = z.object({
  reference: RoomDetailsSchema.optional(),
  derived: z.record(RoomDetailsSchema).optional()
});

export const DatesSchema = z.record(DateRoomSchema);
export const SurgeProtectionSchema = z.object({
  days_ahead: z.number(),
  duration_in_days: z.number(),
  fix_price: z.number(),
  threshold: z.number(),
  user_recipients: z.array(z.number()).optional().default([]),
  auto_price_protection: z.boolean().optional().default(false),
  decay_speed: z.number().optional(),
  decay_step: z.number().optional()
});

export const ReservationStatusSchema = z.object({
  status_description: z.string(),
  value: z.number()
});

const singleFieldSchema = z.object({
  type: z.literal('single'),
  field: z.string(),
  comparison: z.enum(['<', '>', '<=', '>=', '==', '!=']),
  value: numericFieldSchema,
  priority: z.number(),
  action: z.enum(['set', 'remove']),
  min_stay_value: z.number().nullable()
});

const multipleFieldSchema = z.object({
  type: z.literal('multiple'),
  priority: z.number(),
  action: z.enum(['set', 'remove']),
  min_stay_value: z.number().nullable(),
  statement: z.array(
    z.object({
      field: z.string(),
      value: numericFieldSchema,
      comparison: z.enum(['<', '>', '<=', '>=', '==', '!='])
    })
  )
});

const MinstayFieldSchema = z.union([singleFieldSchema, multipleFieldSchema]);

export const RuleSchema = z.object({
  name: z.string().nonempty('Please enter a name'),
  priority: z.number(),
  rooms: z.array(z.string()).min(1, { message: 'Please select at least one' }),
  fields: z.array(MinstayFieldSchema)
});

export const MinstaySchema = z.object({
  rules: z.array(RuleSchema).optional(),
  enabled_orphan_gap_correction_room_ids: z.array(z.string()).optional()
});

export const PricingSettingsSchema = z.object({
  adjustment: AdjustmentSchema,
  predefined_rates: z.array(z.number()),
  hotel: HotelSchema,
  rooms: RoomsSchema,
  default: z.record(DefaultSchema),
  dates: DatesSchema,
  rpg_arguments: RPGArgumentsSchema,
  features: z.array(z.number()).optional(),
  room_type_tags: RoomTypeTagsSchema,
  monthly_target_occupancy: z.array(z.number()).optional(),
  id: z.number().optional(),
  version: z.number().optional(),
  genieIntelligence_email_date: z.unknown().optional(), // Replace with the appropriate schema
  after_365_daily_averages: After365DailyAveragesSchema,
  model_weights: ModelWeightsSchema,
  rooms_sold_to_groups: MonthlyAveragesSchema,
  segmentation: z
    .record(
      z.object({
        segment_tags: z.array(z.string()),
        segments_settings_corrections: z.boolean(),
        segments_price_estimate: z.boolean(),
        segments_pickupboost: z.boolean(),
        name: z.string()
      })
    )
    .optional(),
  revenue_budget: z
    .object({
      revenue_budget_gross_excl_services: z.record(z.number()).optional(),
      revenue_budget_net_excl_services: z.record(z.number()).optional(),
      revenue_budget_gross_incl_services: z.record(z.number()).optional(),
      revenue_budget_net_incl_services: z.record(z.number()).optional()
    })
    .optional(),
  user_notes: z.array(UserNotesSchema).optional(),
  run_pricing: z.boolean().optional(),
  fetch_inventary: z.boolean().optional(),
  surge_protection: SurgeProtectionSchema,
  reservation_status: z.array(ReservationStatusSchema).optional(),
  minstay: MinstaySchema
});

// Validation function
export const validateBaseSettings = (request: z.infer<typeof PricingSettingsSchema>) => {
  const validatedRequest = PricingSettingsSchema.safeParse(request);
  if (validatedRequest.success) {
    return request;
  } else {
    // Handle validation errors
    const errorMessage = validatedRequest.error.issues.map((issue) => issue.path).join('\n');
    throw new Error(errorMessage);
  }
};

export const PartialPricingSettingsSchema = PricingSettingsSchema.partial();
