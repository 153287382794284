import { Button } from '@common/components/atoms/Button';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useHotelStore } from '@common/store/auth';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import * as z from 'zod';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelUpdate } from '@pages/Client/hooks/useHotelUpdate';

const isWithinRange = (val: number | undefined) => val === undefined || (val >= 30 && val <= 100);
const validationMessage = 'Please enter a value between 30% and 100%.';
const validationEmptyMessage = 'Please enter a value';

const aggressivenessErrorMap: z.ZodErrorMap = (error) => {
  switch (error.code) {
    case 'invalid_type':
      return { message: 'Value should be negative' };
    default:
      return { message: 'Value should be negative' };
  }
};

const targetOccupancySchema = z.object({
  0: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  1: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  2: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  3: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  4: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  5: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  6: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  7: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  8: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  9: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  10: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  11: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  ...Array.from({ length: 12 }, (_, i) => `sold_${i + 1}`).reduce(
    (fields, key) => ({
      ...fields,
      [key]: z
        .number()
        .int()
        .optional()
        .refine((val) => val === undefined || val >= 0, {
          message: 'Please enter a positive value'
        })
    }),
    {}
  ),
  target_occupancy: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
});

const medianLeadTimeSchema = z.object({
  medianLeadTime: z.number().or(z.string().transform(Number))
});

const aggressivenessSchema = z.object({
  PELL_weekday: z
    .number({ errorMap: aggressivenessErrorMap })
    .or(z.string().transform(Number))
    .refine((val) => val < 0, {
      message: 'Value should be negative'
    }),
  PELL_weekend: z
    .number({ errorMap: aggressivenessErrorMap })
    .or(z.string().transform(Number))
    .refine((val) => val < 0, {
      message: 'Value should be negative'
    }),
  do_not_reduce_last_day: z.boolean().optional()
});

const closeOutSalesSchema = z.object({
  close_out_days: z.number().or(z.string()),
  close_out_hour: z.number().or(z.string())
});

const shoulderNightDiscountsSchema = z.object({
  apply_shoulder_night_discounts: z.boolean().default(false),
  shoulder_night_discount_1_day: z.number().or(z.string().transform(Number)).default(0),
  shoulder_night_discount_2_days: z.number().or(z.string().transform(Number)).default(0)
});

const mergedSchema = z.object({
  ...targetOccupancySchema.shape,
  ...medianLeadTimeSchema.shape,
  ...aggressivenessSchema.shape,
  ...closeOutSalesSchema.shape,
  ...shoulderNightDiscountsSchema.shape
});

export const OccupancyStrategy = () => {
  const { t } = useTranslation();
  const { hotelAuthToken } = useHotelStore();
  const location = useLocation();
  const { features } = useFeaturesStore();
  const { view } = useViewStore();
  const isMedianBookingWindowPage = location.pathname.includes('median-booking-window');
  const isAggressivenessPage = location.pathname.includes('aggressiveness');
  const isCloseOutSalesPage = location.pathname.includes('close-out-sales');
  const isMinStayRulesPage = location.pathname.includes('min-stay-rules');
  const isShoulderNightDiscountsPage = location.pathname.includes('shoulder-night-discounts');
  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });

  const isHasAccessAggressiveness = features?.includes(Feature.OccupancyStrategy);

  const {
    isLoading: isSavePricingLoading,
    savePricingSettings,
    isSuccess: isSaveSuccess
  } = useUpdatePricingSettings();

  const { pricingSettingsQuery } = useRoomPrices();
  const { data: pricingSettings } = pricingSettingsQuery;

  const { hotelDetails, query: hotelDetailQuery } = useHotelDetails();
  const {
    updateHotel,
    isLoading: hotelUpdateLoading,
    isSuccess: isHotelUpdateSuccess
  } = useHotelUpdate();

  const getSchema = () => {
    if (isMedianBookingWindowPage) {
      return medianLeadTimeSchema;
    } else if (isAggressivenessPage) {
      return aggressivenessSchema;
    } else if (isCloseOutSalesPage) {
      return closeOutSalesSchema;
    } else if (isShoulderNightDiscountsPage) {
      return shoulderNightDiscountsSchema;
    } else {
      return targetOccupancySchema;
    }
  };

  type FormValues =
    | z.infer<typeof targetOccupancySchema>
    | z.infer<typeof medianLeadTimeSchema>
    | z.infer<typeof aggressivenessSchema>
    | z.infer<typeof closeOutSalesSchema>
    | z.infer<typeof shoulderNightDiscountsSchema>
    | z.infer<typeof mergedSchema>;

  const methods = useForm<FormValues>({
    resolver: zodResolver(getSchema())
  });

  const roomId = Object.keys(pricingSettings?.default ?? {})[0];

  useEffect(() => {
    const defaultValues = {
      medianBookingWindow: {
        medianLeadTime: pricingSettings?.rpg_arguments.median_lead_time
      },
      aggressiveness: {
        PELL_weekday: pricingSettings?.rpg_arguments.PELL_weekday,
        PELL_weekend: pricingSettings?.rpg_arguments.PELL_weekend,
        do_not_reduce_last_day: pricingSettings?.rpg_arguments.do_not_reduce_last_day
      },
      closeOutSales: {
        close_out_days: hotelDetails?.close_out_days,
        close_out_hour: hotelDetails?.close_out_hour
      },
      shoulderNightDiscounts: {
        apply_shoulder_night_discounts:
          Boolean(pricingSettings?.default[roomId]?.shoulder_night_discount_1_day) ||
          Boolean(pricingSettings?.default[roomId]?.shoulder_night_discount_2_days),
        shoulder_night_discount_1_day: Number(
          pricingSettings?.default[roomId]?.shoulder_night_discount_1_day ?? 0
        ),
        shoulder_night_discount_2_days: Number(
          pricingSettings?.default[roomId]?.shoulder_night_discount_2_days ?? 0
        )
      },
      targetOccupancy: {
        monthly: pricingSettings?.monthly_target_occupancy?.reduce(
          (acc, val, index) => ({ ...acc, [index]: val }),
          {}
        ),
        roomNightSold:
          pricingSettings?.rooms_sold_to_groups &&
          Object.entries(pricingSettings.rooms_sold_to_groups).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [`sold_${key}`]: value
            }),
            {}
          ),
        target: pricingSettings?.hotel.target_occupancy
      }
    };

    const setValues = () => {
      if (isMedianBookingWindowPage && defaultValues.medianBookingWindow.medianLeadTime) {
        methods.setValue('medianLeadTime', defaultValues.medianBookingWindow.medianLeadTime);
        return;
      }

      if (isAggressivenessPage) {
        const { PELL_weekday, PELL_weekend, do_not_reduce_last_day } = defaultValues.aggressiveness;
        methods.setValue('PELL_weekday', PELL_weekday as number);
        methods.setValue('PELL_weekend', PELL_weekend as number);
        methods.setValue('do_not_reduce_last_day', do_not_reduce_last_day as boolean);
        return;
      }

      if (isCloseOutSalesPage) {
        const { close_out_days, close_out_hour } = defaultValues.closeOutSales;
        methods.setValue('close_out_days', close_out_days as number);
        methods.setValue('close_out_hour', close_out_hour as number);
        return;
      }

      if (isShoulderNightDiscountsPage) {
        const {
          apply_shoulder_night_discounts,
          shoulder_night_discount_1_day,
          shoulder_night_discount_2_days
        } = defaultValues.shoulderNightDiscounts;
        const shoulderNightDiscountsDay1 = shoulder_night_discount_1_day as unknown as number;
        const shoulderNightDiscountsDay2 = shoulder_night_discount_2_days as unknown as number;
        const applyShoulderNightDiscounts =
          Boolean(shoulderNightDiscountsDay1) ||
          Boolean(shoulderNightDiscountsDay2) ||
          (apply_shoulder_night_discounts as unknown as boolean);

        methods.setValue('apply_shoulder_night_discounts', applyShoulderNightDiscounts);
        methods.setValue('shoulder_night_discount_1_day', shoulderNightDiscountsDay1);
        methods.setValue('shoulder_night_discount_2_days', shoulderNightDiscountsDay2);
        return;
      }

      // Default case - target occupancy
      const { monthly, roomNightSold, target } = defaultValues.targetOccupancy;

      if (roomNightSold) {
        Object.entries(roomNightSold).forEach(([key, value]) => {
          methods.setValue(key as never, value as never);
        });
      }

      if (monthly) {
        Object.entries(monthly).forEach(([key, value]) => {
          methods.setValue(key as never, value as never);
        });
      }

      methods.setValue('target_occupancy', target as number);
    };

    setValues();
  }, [
    methods.setValue,
    isMedianBookingWindowPage,
    isAggressivenessPage,
    isCloseOutSalesPage,
    isShoulderNightDiscountsPage,
    hotelDetails,
    pricingSettings?.monthly_target_occupancy,
    pricingSettings?.rpg_arguments.median_lead_time,
    pricingSettings?.rpg_arguments.PELL_weekday,
    pricingSettings?.rpg_arguments.PELL_weekend,
    pricingSettings?.rpg_arguments.do_not_reduce_last_day,
    pricingSettings?.default.shoulder_night_discount_1_day,
    pricingSettings?.default.shoulder_night_discount_2_days
  ]);

  const onSubmit = async (data: any) => {
    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();

    const getUpdatedSettings = () => {
      if (isMedianBookingWindowPage) {
        return {
          ...latestPricingSettings,
          rpg_arguments: {
            ...latestPricingSettings?.rpg_arguments,
            median_lead_time: data.medianLeadTime
          }
        };
      }

      if (isAggressivenessPage) {
        return {
          ...latestPricingSettings,
          rpg_arguments: {
            ...latestPricingSettings?.rpg_arguments,
            PELL_weekday: data.PELL_weekday,
            PELL_weekend: data.PELL_weekend,
            do_not_reduce_last_day: data.do_not_reduce_last_day
          }
        };
      }

      if (isCloseOutSalesPage) {
        return {
          close_out_days: data.close_out_days,
          close_out_hour: data.close_out_hour,
          token: hotelAuthToken
        };
      }

      if (isShoulderNightDiscountsPage && latestPricingSettings) {
        return {
          ...latestPricingSettings,
          default: Object.keys(latestPricingSettings.default).reduce<{ [key: string]: any }>(
            (acc, roomId) => {
              acc[roomId] = {
                ...latestPricingSettings.default[roomId],
                shoulder_night_discount_1_day: data.shoulder_night_discount_1_day,
                shoulder_night_discount_2_days: data.shoulder_night_discount_2_days
              };
              return acc;
            },
            {}
          )
        };
      }

      // Target Occupancy page
      const monthlyTargetOccupancy = Object.values(data).slice(0, 12);
      const roomsSoldToGroups = Object.values(data).slice(12);
      const roomsSoldToGroupsObject = roomsSoldToGroups.reduce<{ [key: number]: number }>(
        (acc, val, index) => {
          acc[index + 1] = Number(val);
          return acc;
        },
        {}
      );

      return {
        ...latestPricingSettings,
        hotel: {
          ...latestPricingSettings?.hotel,
          target_occupancy: data.target_occupancy
        },
        monthly_target_occupancy: monthlyTargetOccupancy,
        rooms_sold_to_groups: roomsSoldToGroupsObject
      };
    };

    const settings = getUpdatedSettings();

    if (isCloseOutSalesPage) {
      await updateHotel(settings);
      await hotelDetailQuery.refetch();
    } else {
      await savePricingSettings(JSON.stringify(settings));
    }
  };

  const subMenuLink = [
    {
      id: 'target-occupancy',
      label: t('Target Occupancy'),
      to: 'target-occupancy'
    },
    {
      id: 'median-booking-window',
      label: t('Median Booking Window'),
      to: 'median-booking-window'
    },
    ...(isHasAccessAggressiveness || view === 'admin'
      ? [
          {
            id: 'aggressiveness',
            label: t('Aggressiveness'),
            to: 'aggressiveness'
          }
        ]
      : []),
    {
      id: 'close-out-sales',
      label: t('Close Out Sales (Last Day)'),
      to: 'close-out-sales'
    },
    {
      id: 'shoulder-night-discounts',
      label: t('Shoulder Night Discounts'),
      to: 'shoulder-night-discounts'
    }
  ];

  useEffect(() => {
    setIsSuccessRef({ current: isSaveSuccess || isHotelUpdateSuccess });
    if (isSaveSuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isSaveSuccess, isHotelUpdateSuccess]);

  return (
    <FormProvider {...methods}>
      <Page
        header={
          <Header
            title={`${t('Occupancy Strategy')}`}
            tabs={subMenuLink}
            actions={
              isMinStayRulesPage ? null : (
                <Button
                  disabled={isSavePricingLoading || hotelUpdateLoading}
                  isSuccess={isSuccessRef.current}
                  isLoading={isSavePricingLoading}
                  intent="primary"
                  type="submit"
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  {t('Save')}
                </Button>
              )
            }
          />
        }
      >
        <Outlet />
      </Page>
    </FormProvider>
  );
};
