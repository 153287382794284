import { useDocumentTitle } from '@mantine/hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Group, NumberInput, rem, Stack, Text } from '@mantine/core';
import { useEffect } from 'react';

export const ShoulderNightDiscounts = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();
  const applyShoulderNightDiscounts = watch('apply_shoulder_night_discounts');

  useDocumentTitle(t('Shoulder Night Discounts'));

  return (
    <Group align="flex-start" gap="lg">
      <Stack maw={rem(600)}>
        <Text>
          {t(`If you are fully booked 1 or 2 nights before or after a date then your guests have more
          limited options to stay (e.g. maybe a stay of only 2 nights is possible). This reduces the
          demand for that date.`)}
        </Text>
        <Text>
          {t(`You can counter this by adding a discount to these "Shoulder Nights". If the night before or
          after, or the 2 nights before or 2 nights after, a particular date then we will apply the
          discounts here. These are cumulative, so if both the night before and after are booked then
          we will add a discount double to the "+ / - 1 night" value.`)}
        </Text>
      </Stack>

      <Stack gap="md">
        <Controller
          name="apply_shoulder_night_discounts"
          control={control}
          defaultValue={false}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              label={t('Apply Shoulder Night Discounts')}
              checked={value}
              onChange={(event) => {
                onChange(event.currentTarget.checked);
                if (!event.currentTarget.checked) {
                  setValue('shoulder_night_discount_1_day', 0);
                  setValue('shoulder_night_discount_2_days', 0);
                }
              }}
            />
          )}
        />

        <Box>
          <Text fw={500} mb="md">
            {t('Percentage Discount')}
          </Text>
          <Stack gap="sm">
            <Controller
              name="shoulder_night_discount_1_day"
              control={control}
              defaultValue={0}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Group gap="xl" align="center">
                  <Text size="sm" w={100}>
                    +/- 1 night
                  </Text>
                  <NumberInput
                    disabled={!applyShoulderNightDiscounts}
                    value={value}
                    onChange={(val) => onChange(val ?? 0)}
                    min={-100}
                    max={100}
                    step={1}
                    w={120}
                    suffix="%"
                    placeholder="-10%"
                    error={error?.message}
                  />
                </Group>
              )}
            />

            <Controller
              name="shoulder_night_discount_2_days"
              control={control}
              defaultValue={0}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Group gap="xl" align="center">
                  <Text size="sm" w={100}>
                    +/- 2 nights
                  </Text>
                  <NumberInput
                    disabled={!applyShoulderNightDiscounts}
                    value={value}
                    onChange={(val) => onChange(val)}
                    min={-100}
                    max={100}
                    step={1}
                    w={120}
                    suffix="%"
                    placeholder="0%"
                    error={error?.message}
                  />
                </Group>
              )}
            />
          </Stack>
        </Box>
      </Stack>
    </Group>
  );
};
